import React from 'react';
import {
  ContentTwoSided,
  Paragraph,
  ReadMore,
  SectionHeading,
} from '../ui-common';
import {
  LogoWrapper,
  LogoItem,
  LogoImage,
  AwardImage,
  AwardFlex,
  SpinoffItem,
  SpinoffImage,
  SpinoffFlex,
  LogoRow,
} from './styled';

import Sony from './images/sony.png';
import Microsoft from './images/microsoft.png';
import Secom from './images/secom.png';
import JapanPost from './images/japan_post.png';
import Idec from './images/idec.png';
import Nedo from './images/nedo.png';

import JapanPostAward from './images/jp_award.png';
import Jstartup from './images/jstartup.png';

import RoboEarth from './images/roboearth.png';
import SpinoffEth from './images/spinoff_eth.png';

const GoodCompany = () => (
  <ContentTwoSided>
  <div className="flex flex-col min-w-0 break-words  shadow-lg rounded-lg bg-white border-0 ml-16 my-16 px-16 pt-16 pb-8">
    <SectionHeading className="good-company-heading">
      You're in Good Company
    </SectionHeading>
    <AwardFlex>
      <div className="award-item">
        <AwardImage src={JapanPostAward} />
        <Paragraph>
          Winner of 2019 Japan Post Logitech
          <br />
          Innovation Award
        </Paragraph>
      </div>
      <AwardImage alt="J-Startup" className="award-item" src={Jstartup} />
      <div className="award-item sm-screen" />
    </AwardFlex>

    <LogoWrapper>
      <LogoRow>
        <LogoItem>
          <LogoImage alt="Sony" src={Sony} />
        </LogoItem>
        <LogoItem>
          <LogoImage alt="Microsoft" src={Microsoft} />
        </LogoItem>
        <LogoItem>
          <LogoImage alt="Secom" src={Secom} />
        </LogoItem>
      </LogoRow>
      <LogoRow>
        <LogoItem>
          <LogoImage alt="Japan Post" src={JapanPost} />
        </LogoItem>
        <LogoItem>
          <LogoImage alt="IDEC" src={Idec} />
        </LogoItem>
        <LogoItem>
          <LogoImage alt="NEDO" src={Nedo} />
        </LogoItem>
      </LogoRow>
    </LogoWrapper>

    <SpinoffFlex>
      <SpinoffItem>
        <SpinoffImage src={RoboEarth} />
        <Paragraph>
          Rapyuta Robotics is a spinoff from RoboEarth, the first world-wide-web
          for robots
        </Paragraph>
        <ReadMore
          href="http://roboearth.ethz.ch/"
          rel="noreferrer noopener"
          target="_blank"
        >
          Read more
        </ReadMore>
      </SpinoffItem>
      <SpinoffItem>
        <SpinoffImage src={SpinoffEth} />
        <Paragraph>
          Rapyuta Robotics is an official spinoff from ETH Zurich
        </Paragraph>
        <ReadMore
          href="https://ethz.ch/de/wirtschaft-gesellschaft/entrepreneurship/spin-offs.html"
          rel="noreferrer noopener"
          target="_blank"
        >
          Read more
        </ReadMore>
      </SpinoffItem>
    </SpinoffFlex>
  </div>
  </ContentTwoSided>
);

export default GoodCompany;
