import React from 'react'
import { ContentTwoSided, SectionHeading } from './ui-common';
import NipponExpressLogo from '../assets/img/nippon-express-square.jpeg'
import SagawaLogo from '../assets/img/sagawa-square.svg'
import RRLogo from '../assets/img/rr-logo-square.jpeg'

var Testimonials =({}) =>{
    
      return (
  
        <ContentTwoSided>
        <div className="ml-16 py-6 my-16 object-left	">
          <div className="container mx-auto px-4" >
            <SectionHeading className="good-company-heading">
            <span className="text-white"> Testimonials</span>
            </SectionHeading>
            
            <div className="md:flex md:flex-wrap md:-mx-4 mb-4">
              <div className="md:w-1/2 md:px-4 mt-6 md:mt-0 ">
                <div className="testimonial bg-gray-100 p-6 border-2 border-solid flex hover:border-gray-100 rounded-lg shadow-lg hover:bg-white transition-colors duration-300">
                  <div className="w-16 h-16 md:w-20 md:h-20 rounded-full overflow-hidden mr-4 md:mr-6 flex-shrink-0">
                    <img src={NipponExpressLogo} alt="profile image" className="w-full h-full object-cover" />
                  </div>
                  <div>
                    <p className="text-gray-600">"The Nippon Express team could collaborate with other engineers around the globe to add on new enterprise - grade applications at scale."
                    </p>
                    <div className="text-gray-900 font-bold uppercase mt-6">- Head of Site Operations</div>
                    <div className="text-gray-600">Nippon Express</div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 md:px-4 mt-6 md:mt-0">
                <div className="testimonial bg-gray-100 p-6 border-2 border-solid flex hover:border-gray-100 rounded-lg shadow-lg hover:bg-white transition-colors duration-300">
                  <div className="w-16 h-16 md:w-20 md:h-20 rounded-full overflow-hidden mr-4 md:mr-6 flex-shrink-0">
                    <img src={SagawaLogo} alt="profile image" className="w-full h-full object-cover" />
                  </div>
                  <div>
                    <p className="text-gray-600">"Our operations productivity for rollouts and updates has increased 2 folds. Compared to the manual process, our MTTR for incidents has decreased by over 90%"</p>
                    <div className="text-gray-900 font-bold uppercase mt-6">- Warehouse Manager</div>
                    <div className="text-gray-600">Sagawa Global Sales Logistics</div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 md:px-4 mt-6">
                <div className="testimonial bg-gray-100 p-6 border-2 border-solid flex hover:border-gray-100 rounded-lg shadow-lg hover:bg-white transition-colors duration-300">
                  <div className="w-16 h-16 md:w-20 md:h-20 rounded-full overflow-hidden mr-4 md:mr-6 flex-shrink-0">
                    <img src={RRLogo} alt="profile image" className="w-full h-full object-cover" />
                  </div>
                  <div>
                    <p className="text-gray-600">"With rapyuta.io, we were able to ship more than 1 Million items in 2 month with 99.98% reliability. The next 1 Million items tooks less than a month.""</p>
                    <div className="text-gray-900 font-bold uppercase mt-6">- Ryusuke Ichimiya, Operations</div>
                    <div className="text-gray-600">Rapyuta Robotics PA-AMR</div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 md:px-4 mt-6">
                <div className="testimonial bg-gray-100 p-6 border-2 border-solid flex hover:border-gray-100 rounded-lg shadow-lg hover:bg-white transition-colors duration-300">
                  <div className="w-16 h-16 md:w-20 md:h-20 rounded-full overflow-hidden mr-4 md:mr-6 flex-shrink-0">
                    <img src={RRLogo} alt="profile image" className="w-full h-full object-cover" />
                  </div>
                  <div>
                    <p className="text-gray-600">"Using rapyuta.io SDK and infrastructure, our Continuous Integration and OTA update process has boiled down to a one click process and takes less than 30 minutes"</p>
                    <div className="text-gray-900 font-bold uppercase mt-6">- Gautham Manoharan, Engineering</div>
                    <div className="text-gray-600">Rapyuta Robotics PA-AMR</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </ContentTwoSided>
      );
    }



  export default Testimonials