import React, { useState } from "react"

import FancyInput from "../FancyInput"

const PasswordInput = ({ valid, ...inputProps }) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="relative pr-10">
      <svg
        className="h-5 w-5 absolute right-5 top-1/2 cursor-pointer"
        style={{ transform: "translateY(-50%)" }}
        viewBox="0 0 20 20"
        fill="#6b7280"
        onClick={() => {
          setShowPassword(!showPassword)
        }}
      >
        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
        <path
          fillRule="evenodd"
          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
          clipRule="evenodd"
        />
      </svg>
      <FancyInput
        className="pr-10"
        type={showPassword ? "text" : "password"}
        {...inputProps}
      />
    </div>
  )
}

export default PasswordInput
