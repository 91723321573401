import { createGlobalStyle } from 'styled-components';

export const COLOR_BLUE = '#4DA8B5';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  #___gatsby {
    padding-top: 106px;
    width: 100%;
    overflow-x: hidden;
  }
  html {
    scroll-behavior: smooth;
    width: 100vw;
    max-width: 100%
  }
  input, button, select {
    outline: 0;
    &:active, &:focus {
      outline: 0;
    }
  }
  button {
    cursor: pointer;
  }
  .minTablet {
    display: none;
    @media screen and (min-width: 440px) {
      display: initial;
    }
  }
  .minDesktop {
    display: none;
    @media screen and (min-width: 770px) {
      display: initial;
    }
  }
  .minWidescreen {
    display: none;
    @media screen and (min-width: 1030px) {
      display: initial;
    }
  }
`;
