import React from "react"
import { Link, navigate } from "gatsby"
import { window } from "browser-monads"

import { getRedirectPathFromUrl, API_CALL_STATUSES, redirectToGoogleOAuth } from "../../utils"

import MailImg from "../../assets/img/mail.png"
import FancyInput from "../FancyInput"
import PasswordInput from "../passwordInputs/PasswordInput"
import ConfirmPasswordInput from "../passwordInputs/ConfirmPasswordInput"
import RRLogo from '../../assets/img/rr-logo-square.jpeg'
import Google  from '../../assets/img/google.svg'


const RegisterForm = ({
  planData,
  selectedPlanIndex,
  // showCaptchaMessage,
  // captchaRef,
  registerStatus,
  formData: {
    emailReadOnly,
    password,
    email,
    confirmPassword
  },
  errorMessage,
  validateInputByType,
  formTouched,
  validateForm,
  onBlur,
  updateValue,
  // onCaptchaChange,
  setSelectedPlan,
  onRegisterSubmit,
}) => {
  
  const onGoogleLogin = e => {
    navigate(redirectToGoogleOAuth())
  }
  
  return (<div className="registerOuterContainer ">
      {registerStatus === API_CALL_STATUSES.SUCCESS ? (
        <div className="max-w-md w-full space-y-8 bg-white py-16 px-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={MailImg}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Almost Done</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            <span className="text-xs  text-gray-600 ">
              Please confirm your email at
              <span id="successEmail text-gray-900" style={{padding:"0 4px"}}>{email}</span>
              to continue with account creation.
              <br />
              Haven't received an email?
              <br />
              <i>Please also check your spam folder</i> or{" "}
              <Link
                to={`/resendVerify/${getRedirectPathFromUrl()}&email=${encodeURIComponent(
                  email
                )}`}
                className="registerResend textBtn"
              >
                <span class="text-red-500 hover:text-red-600"> resend verification email</span>
              </Link>
            </span>
          </p>
        </div>
        </div>
      ) : (
        <div className="rounded flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={RRLogo}
            alt="rapyuta robotics free trial"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Start your <br /> 90-day Free Trial</h2>
        </div>
        <div className="btn-wrapper text-center">
          <button
            onClick={onGoogleLogin}
            className="bg-white active:bg-gray-100 text-gray-800 font-light px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs"
            type="button"
            style={{transition: "all 0.15s ease 0s"}}
          >
            <img
              alt="..."
              className="w-8 mr-1"
              src={Google}
            />Sign up with Google
          </button>
        </div>
        <hr />
        <form id="registerForm" onSubmit={onRegisterSubmit} className="mt-8 space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
           <select style={{display: 'none'}}
            type="hidden"
             id="planNameList"
             className="dropDown"
             value={selectedPlanIndex}
           >
               <option value={0} selected={true}>Community</option>
           </select>

          <div className="rounded-md shadow-sm pb-4 flex flex-col justify-center">
            <div>
              <FancyInput
                data-id= "email"
                type="email"
                name="email"
                placeholder="Email address"
                id="emailRegister"
                required
                readOnly={emailReadOnly}
                tabIndex={emailReadOnly ? -1 : 0}
                onBlur={() => onBlur("email")}
                value={email}
                onChange={updateValue}
                />
               
            </div>
            <div className="my-4">
              <PasswordInput
                data-id= "password"
                placeholder="Password"
                name="password"
                id="passwordRegister"
                required
                value={password}
                onChange={updateValue}
                onBlur={() => onBlur("password")}
              />
            </div>
            <div className="mb-4">
                <ConfirmPasswordInput
                  data-id= "confirmPassword"
                  type="password"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  id="confirmPasswordRegister"
                  required
                  value={confirmPassword}
                  onChange={updateValue}
                  onBlur={() => onBlur("confirmPassword")}
                  valid={validateInputByType('confirmPassword')}
                 />
                  {formTouched && !validateInputByType("email") && (
                  <div class="text-xs mt-4 mb-4 text-red-800">
                    Not a valid email address
                  </div>
                )}
                
                 {formTouched && !validateInputByType("password") && (
                   <div class="text-xs mt-4 mb-4 text-red-800">
                     Password must be a minimum of 8 characters long with at least:
                     one uppercase letter, one lowercase letter, one number, and one
                     character among _-!@#$%^&*
                   </div>
                 )}
                 {errorMessage &&  registerStatus === API_CALL_STATUSES.ERROR && <div class="text-xs mt-4 mb-4 text-red-800">{errorMessage}</div> }
            </div>
          </div>


          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-bold rounded-md text-white bg-red-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              className="redBtn"
              id="submitButton"
              type="submit"
              disabled={
                (formTouched && !validateForm()) || 
                registerStatus === API_CALL_STATUSES.PROGRESS
              }
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                
              </span>
              SIGN UP
            </button>

            <p className="mt-6  px-9 text-center text-sm text-gray-600">
            <p  className="text-xs  text-gray-600 ">
              No credit-card required during free trial.  
              <br />Paid resources <strong>beyond free-tier usage</strong> would require a credit card
            </p>
          </p>
          </div>
        </form>
      </div>
    </div>
      )}
      <div className="flex bg-gray-50 py-4 px-4 my-10 rounded flex items-center">
        <p  className="flex flex-grow text-xs  text-gray-600">
          Already have an account? 
        </p>
        <a className="font-semibold uppercase text-sm text-red-700 hover:text-indigo-500" id="loginLink" href="/login/">
        Sign IN
        </a>
      
      </div>
    
  </div>)
}

export default RegisterForm
