import { Link } from 'gatsby'
import React from 'react'



const PayAsYouGo = ({}) => {
  return (
    <div className="w-full max-w-md sm:w-2/3 lg:w-1/3 sm:my-5 my-8 relative z-10 bg-white rounded-lg shadow-lg">
      <div className="text-sm leading-none rounded-t-lg bg-red-700 text-white font-semibold uppercase py-4 text-center tracking-wide">
        90-day Free Trial
        <br className="mt-2 border-b-1 border-black my-2" />
        <span className="font-medium text-xs">No credit card requried</span>
      </div>
      <div className="block text-left text-lg sm:text-md max-w-sm mx-auto mt-0 text-black px-0 lg:px-6 py-8">
        <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center font-semibold  tracking-wide">
          Pay-as-you-Grow
        </h1>
        
        <div className="mt-4">
        Connect upto 6 devices for free during trial. 
        Included Free-tier for paid resources: <span className="font-semibold">cloud vCPUs, storage &amp; static-routes.</span>
        </div>
      </div>
      <hr className="mt-4 border-b-1 border-blueGray-400 my-1" />
      <div className="flex pl-5 justify-start sm:justify-start mt-3">
        <ul>
          <li className="flex items-center">
            <div className="rounded-full p-2 fill-current text-green-700">
              <img src='/icons/setup.svg' alt="Gear with wrench" />
            </div>
            <span className="text-gray-700 text-lg ml-3">Instant setup</span>
          </li>
          <li className="flex items-center">
            <div className=" rounded-full p-2 fill-current text-green-700">
              <img src="/icons/device_grey.svg" alt="Hard disk with plus symbol" />
            </div>
            <span className="text-gray-700 text-lg ml-3">6 Devices included</span>
          </li>
          <li className="flex items-center">
            <div className=" rounded-full p-2 fill-current text-green-700">
              <img src="/icons/cloud_usage.svg" alt="Cloud with bi-directional arrows" />
            </div>
            <span className="text-gray-700 text-lg ml-3">100 vCPU-hr Cloud Usage</span>
          </li>

          <li className="flex items-center">
            <div className=" rounded-full p-2 fill-current text-green-700">
              <img src="/icons/users.svg" alt="Outline of three people" />
            </div>
            <span className="text-gray-700 text-lg ml-3">5 Users included</span>
          </li>
          <hr className="mt-6 border-b-1 border-blueGray-400" />
        </ul>
        
      </div>
    </div>

  )
}

const Enterprise = ({}) => {
  return (
    <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-2/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-ml-4">
      <div
        className="bg-blueGray-600 text-black rounded-lg shadow-inner shadow-lg overflow-hidden"
        style={{ background: "#181e21b8" }}
      >
        <h1
          className="bg-black text-gray-100 text-lg font-semibold uppercase p-3 pb-0 text-center tracking-wide"
          style={{ background: "#313131" }}
        >
          Enterprise
        </h1>
        <div className="block text-left text-sm sm:text-md max-w-sm mx-auto text-black ">
          <div className="py-8 px-4 text-center text-gray-200 text-lg">
            rapyuta.io offers customized enterprise plans for scaling your
            robotics application.
          </div>
        </div>
        <hr className="border-b-1 border-blueGray-400 mb-4 mt-0" />
        <div className="flex flex-wrap mt-3 px-6">
          <ul>
            <li className="flex items-center">
              <div className=" rounded-full p-2 fill-current text-green-600">
                <img src="/icons/device_transparent.svg" alt="Hard disk with plus symbol" />
              </div>
              <span className="text-gray-100 text-lg ml-3">
                Unlimited Devices
              </span>
            </li>
            <li className="flex items-center">
              <div className=" rounded-full p-2 fill-current text-green-600">
                <img src="/icons/hardware.svg" alt="Electronic chip" />
              </div>
              <span className="text-gray-100 text-lg ml-3">
                Hardware Drivers
              </span>
            </li>
            <li className="flex items-center">
              <div className=" rounded-full p-2 fill-current text-green-600">
                <img src="/icons/integration.svg" alt="Gear" />
              </div>
              <span className="text-gray-100 text-lg ml-3">
                Integration Services
              </span>
            </li>
            <li className="flex items-center">
              <div className=" rounded-full p-2 fill-current text-green-600">
                <img src="/icons/priority_support.svg" alt="Lightning with ripples going out" />
              </div>
              <span className="text-gray-100 text-lg ml-3">
                Priority Support
              </span>
            </li>
          </ul>
        </div>
        <div className="block flex items-center p-8  uppercase">
          <Link
            class="w-full hover:no-underline"
            to={
              window?.RR_EXT_URLS?.TALK_TO_US ||
              "https://meetings.hubspot.com/krishna-r"
            }
            target="_blank"
          >
            <button
              className="mt-3 text-lg font-semibold 
            w-full text-white rounded-lg 
          px-6 py-3 block shadow-xl hover:bg-white hover:bg-opacity-10 border border-solid border-white"
            >
              Talk to Us
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
const Pricing = ({}) => {
      return (
        <section className="mx-8">
          <div className="container ">
         
            <div className="max-w-full md:max-w-6xl mx-auto my-3 md:px-8">
              <div className="relative block flex flex-col md:flex-row items-center">
                <PayAsYouGo />
                <Enterprise />
              </div>
            </div>
          </div>
        </section>
      );
  };

export default Pricing


// <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-mr-4">
// <div className="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden">
//   <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
//     <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
//       Hobby 
//     </h1>
//     <h2 className="text-sm text-gray-500 text-center pb-6">FREE</h2>
//     Stripe offers everything needed to run an online business
//     at scale. Get in touch for details.
//   </div>
//   <div className="flex flex-wrap mt-3 px-6">
//     <ul>
//       <li className="flex items-center">
//         <div className=" rounded-full p-2 fill-current text-green-700">
//           <svg className="w-6 h-6 align-middle" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
//             <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
//             <polyline points="22 4 12 14.01 9 11.01" />
//           </svg>
//         </div>
//         <span className="text-gray-700 text-lg ml-3">No setup</span>
//       </li>
//       <li className="flex items-center">
//         <div className=" rounded-full p-2 fill-current text-green-700">
//           <svg className="w-6 h-6 align-middle" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
//             <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
//             <polyline points="22 4 12 14.01 9 11.01" />
//           </svg>
//         </div>
//         <span className="text-gray-700 text-lg ml-3">No setups</span>
//       </li>
//       <li className="flex items-center">
//         <div className=" rounded-full p-2 fill-current text-green-700">
//           <svg className="w-6 h-6 align-middle" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
//             <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
//             <polyline points="22 4 12 14.01 9 11.01" />
//           </svg>
//         </div>
//         <span className="text-gray-700 text-lg ml-3">Speed</span>
//       </li>
//     </ul> 
//   </div>  
//   <div className="block flex items-center p-8  uppercase">
//     <button className="mt-3 text-lg font-semibold 
// bg-black w-full text-white rounded-lg 
// px-6 py-3 block shadow-xl hover:bg-gray-700">
//       Select
//     </button>
//   </div>
// </div>
// </div>
