import React from 'react'
import { ContentTwoSided, SectionHeading } from './ui-common'
import VideoIcon from '../assets/img/video-icon.png'


const FeatureGroup = ({text}) => (
    <div className="flex items-center h-12 px-4 bg-blueGray-200 border-b border-gray-500">
        <div className="font-semibold">{text}</div>
    </div>
)

const FeatureFlag = ({checked, isSecond = false}) => {
    return checked ? (
    <div className={`flex justify-center  items-center flex-grow w-0 border-r-2 ${isSecond ? "" : "border-l-2"} border-solid h-8`}>
        <svg
            className="w-4 h-4 text-green-600 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
            />
        </svg>
    </div>
    ): <div className={`flex justify-center items-center flex-grow w-0 border-r-2 ${isSecond ? "" : "border-l-2"} border-solid h-8`} />
}


const Feature = ({text , first, second , third}) => (
    <div className="flex items-center h-8 pl-4 border-b border-gray-500">
        <div className="flex flex-row items-center w-60 text-xs font-medium text-blueGray-600">
        <img className="px-4" src={VideoIcon} style={{height:"16px"}} />    {text}
        </div>
        <FeatureFlag checked={first}  />
        <FeatureFlag checked={second} isSecond />
        {/* <FeatureFlag checked={third} /> */}
    </div>
)
const FeatureComparison = ({}) => {

    return  (
        <ContentTwoSided>
        <div className="ml-16 py-6 my-16 object-left">
          <div className="container mx-auto px-4" >
            <SectionHeading className="good-company-heading">
            <span className="text-white">  Plan Comparison</span>
            </SectionHeading>
            </div>


        <div className="flex flex-col w-full bg-white  rounded-lg shadow-lg">
            <div className="flex items-center h-20 px-4 border-b border-gray-500 bg-gray-900 text-white">
                <div className="w-60" />
                <div className="flex-grow text-lg font-semibold text-center  ">Pay-as-you-Grow</div>
                <div className="flex-grow text-lg font-semibold text-center">ENTERPRISE</div>
            </div>
            
            

            <FeatureGroup text="Device Operations" />
            <Feature text="Docker Deployment" first second/>
            <Feature text="Build Engine Deployments" first second/>
            <Feature text="OTA Updates" first second/>
            <Feature text="Remote File Management" first second/>
            <Feature text="Remote SSH" first second/>
            <Feature text="OS Patch Management" second />
            <Feature text="Vulnerability Scan" second />
            <Feature text="Secure VPN"  second/>


            <FeatureGroup text="ROS Support" />
            <Feature text="ROS 1 Multi-Master" first second/>
            <Feature text="ROS 1 Device to Cloud" first second/>
            <Feature text="ROS 1 Multi Device" first second/>
            <Feature text="ROS 2"  second/>
            

            <FeatureGroup text="Cloud Robotics" />
            <Feature text="Build Engine" first second />
            <Feature text="Cloud Simulation" first second />
            <Feature text="rapyuta.io Marketplace" first second />
            
            <Feature text="GPU Support" second />
            <Feature text="Serverless Function" second />
            <Feature text="Push to Private Registry"  second />
            


            <FeatureGroup text="Logging & APM" />
            <Feature text="Rosbag Collection" first second/>
            <Feature text="Streaming Logs" first second/>
            <Feature text="Historical Logs"  first second/>
            <Feature text="Tracing"  second/>

            
            
            <FeatureGroup text="Monitoring" />
            <Feature text="Device Metrics" first second/>
            <Feature text="Grafana Integration"  first second/>
            <Feature text="Custom Metrics"  second/>
            <Feature text="Rule based Alerting"  second/>
            <Feature text="Webhook Support"  second/>
            


            


            <FeatureGroup text="Developer Tools" />
            <Feature text="Cloud IDE" first second />
            <Feature text="CLI" first second />
            <Feature text="SDK" first second />
            <Feature text="Ansible " second />

            

            <FeatureGroup text="Account Management" />
            <Feature text="Unlimited Users &amp; Projects" second />
            <Feature text="Invoice Billing" second />
            <Feature text="Commitment Discounts" second />
            <Feature text="Role Based Access" second />
            <Feature text="Audit Logs" second />
            


            
        </div>
        </div>

        </ContentTwoSided>
    )
}

export default FeatureComparison