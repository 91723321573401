import styled from 'styled-components';
import { Paragraph, ReadMore } from '../../ui-common';

export const LogoItem = styled.div`
  padding: 10px 0;

  @media screen and (max-width: 1280px) {
    flex: 0 0 31%;
  }

  @media screen and (max-width: 620px) {
    margin-right: 10px;
  }

  @media screen and (max-width: 480px) {
    margin-right: 0px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  padding: 35px 0 50px;
  flex-wrap: wrap;
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    flex-direction: row;

    ${LogoItem} {
      margin-right: 60px;
    }
  }
`;

export const LogoRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    border-bottom: 1px solid #ededed;
  }
`;

export const LogoImage = styled.img`
  height: 60px;

  @media screen and (max-width: 10240px) {
    height: 45px;
  }

  @media screen and (max-width: 640px) {
    height: 35px;
  }

  @media screen and (max-width: 480px) {
    height: 25px;
  }
`;

export const AwardImage = styled.img`
  height: 194px;

  @media screen and (max-width: 540px) {
    width: 200px;
  }

  @media screen and (max-width: 480px) {
    width: 100px;
    height: 100px;
  }
`;

export const AwardFlex = styled.div`
  display: flex;
  justify-content: flex-start;

  .award-item {
    padding-right: 40px;

    &.sm-screen {
      display: none;
    }
  }

  @media screen and (max-width: 678px) {
    .award-item {
      padding-right: 0;

      &:first-child {
        padding-right: 20px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    border-bottom: 1px solid #ededed;
    justify-content: space-between;
    padding-bottom: 10px;

    .award-item {
      flex: 0 0 32%;

      img {
        width: 100%;
      }

      &.sm-screen {
        display: block;
      }
    }

    ${Paragraph} {
      display: none;
    }
  }
`;

export const SpinoffItem = styled.div`
  margin-right: 180px;
  width: 360px;

  @media screen and (max-width: 678px) {
    margin-bottom: 50px;
    margin-right: 20px;
  }

  @media screen and (max-width: 440px) {
    margin-bottom: 50px;
    width: 100%;
  }
`;

export const SpinoffImage = styled.img`
  height: 35px;
  margin-bottom: 20px;

  @media screen and (max-width: 420px) {
    height: 26px;

    ${Paragraph} {
      font-size: 13px;
    }

    ${ReadMore} {
      font-size: 12px;
    }
  }
`;

export const SpinoffFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 100px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
