import React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "gatsby"
import { window } from "browser-monads"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Progress from "../components/progress"
import Footer from "../components/footer"
import GoodCompany from "../components/goodCompany"
import RegisterFormWrapper from "../components/register"
import TokenExist from "../components/tokenExists"
import ClientOnly from "../components/clientOnly"

import {
  ajax,
  API_CALL_STATUSES,
  getQueryParam,
  getRedirectPathFromUrl,
  getServiceQueryParam,
  isNumeric,
  getProcessedPlanData,
  getCookie,
} from "../utils"
import Testimonials from "../components/Testinonials"
import FeatureComparison from "../components/FeatureComparison"
import { RCB_PLANS } from '../utils/rcb'


const validateUserName = name => {
  const usernameRegex = /^([^~!@#$%^&*()<>,?{};':;+=\"\[\]\\/\x60]){1,40}$/m;
  return !name || (!isNumeric(name) && usernameRegex.test(name));
}

const SECONDARY_LINK_STYLE = {
  color: "#4da8b5",
  cursor: "pointer"
}

class Register extends React.Component {
  constructor(props) {
    super(props)

    const urlEmail = getQueryParam("email") || ""
    const { firstName, lastName } = urlEmail ? this.inferNameFromEmail(urlEmail) : { firstName: "", lastName: ""};
    
    this.grecaptchaRef = React.createRef()
    this.state = {
      email: urlEmail,
      firstName: firstName,
      lastName: lastName,
      password: "",
      confirmPassword: "",
      registerStatus: API_CALL_STATUSES.IDLE,
      registerErrorCode: "",
      registerErrorMsg: "",
      emailReadOnly: urlEmail !== "",
      formTouched: false,
      showCaptchaMessage: false,
      grecaptchaValue: "",
      planFetchStatus: API_CALL_STATUSES.IDLE,
      planData: getProcessedPlanData(),
      selectedPlanIndex: 1,
    }

    this.validateInputItem = this.validateInputItem.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this.onCaptchaChange = this.onCaptchaChange.bind(this)
    this.setSelectedPlan = this.setSelectedPlan.bind(this)
    this.onRegisterSubmit = this.onRegisterSubmit.bind(this)
  }

  componentDidMount() {
    if (!getCookie("token")) {
      this.getPlanDetails()
    }
  }

  setSelectedPlan = evt => {
    const {
      target: { value },
    } = evt
    this.setState({ selectedPlanIndex: value })
  }

  onBlur = inputName => {
    const initialValue = this.state[inputName]
    this.setState({
      formTouched: true,
      [inputName]: (initialValue || "").trim(),
    })
  }

  validateForm = () => {
    let isValidForm = true;
    ["email", "firstName", "lastName", "password", "confirmPassword"].forEach(
      itemName => {
        const validationResult = this.validateInputItem(itemName)
        if (!validationResult) {
          isValidForm = false
        }
      }
    )
    return isValidForm
  }

  validateInputItem = inputItemName => {
    const { firstName, lastName, email, password, confirmPassword } = this.state
    let regex = /^[A-Za-z]+$/
    const{ allowPlusEmail } = window;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const disallowPlusEmailRegex = /^(([^<>()[\]\\.,;:\s@+"]+(\.[^<>()[\]\\.,;:\s@+"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    switch (inputItemName) {
      case "firstName":
      case "lastName":
        return validateUserName(firstName) && typeof lastName === 'string';
      case "email":{
        if(allowPlusEmail) {
          regex = emailRegex;
        } else {
          regex = disallowPlusEmailRegex;
        }
      }
        return !email || regex.test(email)
      case "password":
        regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[$@!*\_\-#%\^&])[A-Za-z\d$@!*\_\-#%\^&]{8,}$/
        return !password || regex.test(password)
      case "confirmPassword":
        return confirmPassword && this.validateInputItem('password') && confirmPassword === password;
    }
  }

  getErrorMessage = status => {
    const { email, registerErrorMsg } = this.state
    switch (status) {
      case 409:
        return (
          <div class="text-gray-500">
            <span class="text-red-500">User has already been registered.</span>
            <br />
            <br />
            <Link style={SECONDARY_LINK_STYLE}
              to={`/resendVerify/${getRedirectPathFromUrl()}&email=${encodeURIComponent(
                email
              )}`}
            >
              Resend Email
            </Link>
            &nbsp; if you haven't received your verification email or the link has expired

            <br />
            <br />
            <Link style={SECONDARY_LINK_STYLE}
              to={`/forgotPassword/${getRedirectPathFromUrl()}`}>
              Reset Password
            </Link>{" "}
            if you forgot your account password
          </div>
        )
      case 500:
        return "Registration failed due to server error. Please try again later."
      case 400:
        return registerErrorMsg ?? "Bad request";
      default:
        return "Failed to register"
    }
  }

  onRegisterSubmit = e => {
    e.preventDefault()

    let {
      email,
      firstName,
      lastName,
      password,
      confirmPassword
    } = this.state
    
    if(email!== "" && firstName === "" && lastName === "")
    {
      const inferredNameParts = this.inferNameFromEmail(email)
      firstName = inferredNameParts?.firstName || email
      lastName = inferredNameParts?.lastName || email
    }
    
    ajax({
      path: "user/register",
      headers: { path: "/", service: getServiceQueryParam() },
      type: "POST",
      data: {
        // "g-recaptcha-response": grecaptchaValue,
        // captcha: grecaptchaValue,
        email,
        firstName,
        lastName,
        password,
        confirmPassword,
      },
      success: () => {
        this.setState({
          registerStatus: API_CALL_STATUSES.SUCCESS,
        })
      },
      error: (err, res) => {
        //this.grecaptchaRef.current.reset()
        this.setState({
          grecaptchaValue: "",
          registerStatus: API_CALL_STATUSES.ERROR,
          registerErrorCode: err && err.status ? err.status : "",
          registerErrorMsg: res.error
        })
      },
    })
  }

  onCaptchaChange = grecaptchaValue => {
    this.setState({
      grecaptchaValue,
      showCaptchaMessage: !grecaptchaValue,
    })
  }


  capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) => {
    if (!(first && rest)) {
      return ""
    }
    if (first && !rest)
      return first.toLocaleUpperCase(locale)

    if (first && rest)
      return first.toLocaleUpperCase(locale) + [rest || ""].join('')

  }


  inferNameFromEmail = (email) => {
    let nameParts = email
    if (email.indexOf("@") !== -1) {
      const parts = email.split("@")
      nameParts = parts[0]
    }

    const nameSubparts = nameParts.split(/[^A-Za-z0-9]/)
    if (nameSubparts && nameSubparts.length > 1) {
      const firstName = nameSubparts[0]
      let lastName = nameSubparts.slice(1).join(" ")
      lastName = lastName.trim()
      lastName = lastName !== "" ? lastName : "-"

      return { firstName, lastName }
    }


    if (nameSubparts && nameSubparts.length == 1)
      return { firstName: nameSubparts[0], lastName: "-" }

    return { firstName: nameParts, lastName: "-" }
  }


  updateValue = e => {
    const {
      target: {
        dataset: { id },
        value,
      },
    } = e
    e.preventDefault()
    var updatePayload = {
      [id]: value,
    }
    if (id === "email") {
      const { firstName, lastName } = this.inferNameFromEmail(value)
      updatePayload["firstName"] = this.capitalizeFirstLetter(firstName)
      updatePayload["lastName"] = this.capitalizeFirstLetter(lastName) == "" ? lastName : this.capitalizeFirstLetter(lastName)
    }
    this.setState(updatePayload)
  }

  getPlanDetails() {
    const { coreEndpoint } = window;
    const service = (getQueryParam("service") || "ioconsole").toLowerCase()
    const ioPlanName = (getQueryParam("io_plan_name") || "Community").toLowerCase()

    if (service === "ioconsole" && ioPlanName) {
      this.setState({ planFetchStatus: API_CALL_STATUSES.PROGRESS })

      const planData = getProcessedPlanData(RCB_PLANS.data)
      const selectedPlanIndex = planData.findIndex(
        ({ displayName }) => displayName.toLowerCase() === ioPlanName
      )

      this.setState({
        selectedPlanIndex,
        planFetchStatus: API_CALL_STATUSES.SUCCESS,
        planData,
      })

    }
  }

  render() {
    const {
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
      emailReadOnly,
      registerStatus,
      registerErrorCode,
      showCaptchaMessage,
      formTouched,
      planFetchStatus,
      planData,
      selectedPlanIndex,
    } = this.state

    // if (getCookie("token")) return <TokenExist title="Register" />
    return (
      <Layout bgimage dark >
        <SEO title="Register" />
        {(planFetchStatus === API_CALL_STATUSES.PROGRESS ||
          registerStatus === API_CALL_STATUSES.PROGRESS) && <Progress />}
        {planFetchStatus !== API_CALL_STATUSES.PROGRESS && (
          <ClientOnly className="relative flex flex-col">
            <React.Fragment>
              <div id="containerOuter">
                <RegisterFormWrapper
                  onRegisterSubmit={this.onRegisterSubmit}
                  selectedPlanIndex={selectedPlanIndex}
                  onBlur={this.onBlur}
                  planFetchStatus={planFetchStatus}
                  planData={planData}
                  captchaRef={this.grecaptchaRef}
                  registerStatus={registerStatus}
                  validateInputByType={this.validateInputItem}
                  showCaptchaMessage={showCaptchaMessage}
                  formTouched={formTouched}
                  formData={{
                    email,
                    firstName,
                    lastName,
                    password,
                    confirmPassword,
                    emailReadOnly,
                    confirmPassword,
                  }}
                  errorMessage={this.getErrorMessage(registerErrorCode)}
                  validateForm={this.validateForm}
                  updateValue={this.updateValue}
                  onCaptchaChange={this.onCaptchaChange}
                  setSelectedPlan={this.setSelectedPlan}
                  showPlanSection={
                    planFetchStatus === API_CALL_STATUSES.SUCCESS &&
                    planData.length === 2
                  }
                />

                <Testimonials />
                <FeatureComparison />

                <GoodCompany />



              </div>

            </React.Fragment>
          </ClientOnly>
        )}
      </Layout>
    )

  }
}

export default Register
