import styled from 'styled-components';
// import { Link } from 'gatsby';
import { COLOR_BLUE } from './globalStyle';

import { Illustration1, arrowSvg as ReadmoreIcon } from './images/';

const DEFAULT_SIDE_PADDING = '19px';
export const COLOR_RED = '#D61B26';
export const ALink = styled.a`
  text-decoration: none;
  font-family: 'Poppins';
  color: white;
  cursor: pointer;
`;
export const ALinkLightBg = styled(ALink)`
  color: #020202;

  &.doc {
    margin-left: 2rem;
  }
`;

export const ContentTwoSided = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  left: 0;
  padding: 0 ${DEFAULT_SIDE_PADDING};

  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`;

export const ContentRight = styled.div`
  width: 100%;
  padding-left: calc(50% + ${DEFAULT_SIDE_PADDING} - 700px);
  @media screen and (max-width: 1400px) {
    padding-left: ${DEFAULT_SIDE_PADDING};
    padding-right: ${DEFAULT_SIDE_PADDING};
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexGap = styled.div`
  width: 0;
  flex-grow: 1;
`;

const headingStyles = `
  text-align: left;
  font: 800 42px/45px Poppins;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: -25px;
    width: 32px;
    height: 10px;
    border-radius: 5px;
    background-color: #D61B26;
    opacity: 1;
  }
  @media screen and (max-width: 440px) {
    font: 800 30px/40px Poppins;
  }
`;

export const PageHeading = styled.h1`
  ${headingStyles}
  line-height: 50px;
`;

export const SectionHeading = styled.h2`
  ${headingStyles}
  font: 800 42px/53px Poppins;
  margin-bottom: 60px;
`;

export const Link = styled.a``;

export const ReadMore = styled.a`
  font: 500 16px/25px Poppins;
  color: ${COLOR_BLUE};
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  margin-top: 4px;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
  &::after {
    content: '';
    position: absolute;
    right: -45px;
    top: 4px;
    width: 30px;
    height: 16px;
    background: url(${ReadmoreIcon}) no-repeat;
  }
`;

const primaryButtonStyles = `
  display: inline-block;
  text-transform: uppercase;
  padding: 8px 32px;
  background-color: ${COLOR_RED};
  box-shadow: 0px 30px 40px #D61B2629;
  text-decoration: none;
  font: 700 14px/16px Poppins;
  color: #FFFFFF;
  border-radius: 40px;
  border: 0;
  cursor: pointer;
`;

export const PrimaryButton = styled.button`
  ${primaryButtonStyles}
`;

export const PrimaryLink = styled(Link)`
  ${primaryButtonStyles}
`;

export const PrimaryAnchor = styled.a`
  ${primaryButtonStyles}
`;

export const Paragraph = styled.p`
  font: 400 16px/25px Poppins;
  margin: 0;
  @media screen and (max-width: 440px) {
    font: 400 14px/20px Poppins;
  }
`;

export const BannerItem = styled(Flex)`
  width: 100%;
  align-items: center;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: initial;
  }
`;

export const BannerPadding = styled(ContentTwoSided)`
  position: relative;
  margin-top: 80px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Banner = styled(Flex)`
  position: relative;
  margin-top: 109px; // depends on the header as header is fixed positioned

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    background-image: url(${Illustration1});
    background-position: right bottom;
    background-size: 970px auto;
    left: 0;
    bottom: -600px;
    width: 570px;
    height: 720px;
  }

  @media screen and (max-width: 1000px) {
    &.contact {
      min-height: 650px;
    }
  }

  @media screen and (max-width: 600px) {
    &.contact {
      min-height: 500px;
    }
    &::after {
      width: 190px;
      height: 240px;
      bottom: -200px;
      background-size: 320px auto;
    }
  }
`;

export const BannerVideo = styled.video`
  position: absolute;
  width: 100%;
  right: -100px;
  top: -50px;
  max-width: 850px;
  @media screen and (max-width: 1450px) {
    right: -50px;
  }
  @media screen and (max-width: 1030px) {
    top: -100px;
    max-width: 700px;
    right: 0;
  }
  //@media screen and (max-width: 1000px) {
  //  align-self: flex-end;
  //  position: initial;
  //}
  //@media screen and (max-width: 800px) {
  //  max-width: 100%;
  //}
`;

export const BannerText = styled(Paragraph)`
  line-height: 30px;
  width: 640px;
  margin-bottom: 40px;
  max-width: 100%;
  @media screen and (max-width: 1450px) {
    width: 500px;
  }
`;

export const BannerContent = styled.div`
  min-width: 410px;
  z-index: 10;
  max-width: 100%;
  padding-bottom: 50px;

  @media screen and (max-width: 425px) {
    min-width: 300px;
  }
  @media screen and (max-width: 1000px) {
    margin-top: 30px;
  }
`;
