import React from "react"

import FancyInput from "../FancyInput"

const ConfirmPasswordInput = ({ valid, ...inputProps }) => (
  <div className="relative pr-10">
    <svg
      className={`h-5 w-5 text-green-500 absolute right-5 top-1/2 transition-opacity ${
        valid ? 'opacity-1' : 'opacity-0'
      }`}
      style={{ transform: "translateY(-50%)" }}
      width="48"
      height="48"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" /> <circle cx="12" cy="12" r="9" />
      <path d="M9 12l2 2l4 -4" />
    </svg>
    <FancyInput className="pr-10" {...inputProps} />
  </div>
)

export default ConfirmPasswordInput
