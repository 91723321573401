import React from "react"

import QuestionIcon from '../../assets/img/got-questions.svg'

import { ContentTwoSided } from "../ui-common"
import RegisterForm from "./registerForm"
import PlanSection from "./planSection"
import Pricing from "../Pricing"
import { Link } from "gatsby"

const CTA= ({}) => {
  return (
    <div className="cta-2 ml-16 bg-white py-6 rounded-lg shadow-lg">
    <div className="container px-4 mx-auto">
      <div className="md:flex md:-mx-4 md:items-center xl:py-4">
        <div className="md:w-1/2 px-12">
          <p className="text-2xl text-gray-900">Got any questions</p>
          <p className="text-sm text-gray-600 mt-2">
            Building Robotics &amp; Cloud solutions is a lot of hard work. <br />
          rapyuta.io has helped startups, system integrators and large enterprises put robots into production. 
          Not sure about how our plans work or have a custom requirement? We would love to hear about your needs.
          </p>
        </div>
        <div className="md:w-1/2 px-4 sm:text-right mt-12 md:mt-0">
          <Link to={window?.RR_EXT_URLS?.SCHEDULE_MEETING || "https://meetings.hubspot.com/krishna-r"}  target="_blank">
            <button className="py-2 px-6 bg-gray-900 text-white border-2 border-solid border-white  hover:text-gray-100 rounded mb-3 sm:mb-0 mx-auto sm:mx-2 block sm:inline-block transition-colors duration-300">
              Schedule a Meeting
              </button>
          </Link>
          <Link to={window?.RR_EXT_URLS?.CASE_STUDY || "https://www.rapyuta.io/nippon-express-case-study/" }  target="_blank">
          <button className="py-2 px-6 text-gray-900 border-2 border-solid border-gray-900 hover:bg-white hover:text-indigo-600 rounded mx-auto sm:mx-2 block sm:inline-block transition-colors duration-300">
            Read Case Studies
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
  )
}


const RegisterFormWrapper = ({
  validateInputByType,
  showCaptchaMessage,
  formTouched,
  formData,
  showPlanSection,
  planFetchStatus,
  planData,
  errorMessage,
  validateForm,
  onBlur,
  updateValue,
  onCaptchaChange,
  captchaRef,
  selectedPlanIndex,
  setSelectedPlan,
  onRegisterSubmit,
  registerStatus,
}) => (
  <ContentTwoSided>
    <div id="registerFlex">
      
      {/* <PlanSection 
        selectedPlanIndex={selectedPlanIndex}
        showPlanSection={showPlanSection} 
        planFetchStatus={planFetchStatus}
        planData={planData} 
      /> */}
      <Pricing />
      <RegisterForm
        selectedPlanIndex = {selectedPlanIndex}
        planData={planData}
        planFetchStatus={planFetchStatus}
        onCaptchaChange={onCaptchaChange}
        onBlur={onBlur}
        showPlanSection={showPlanSection}
        validateInputByType={validateInputByType}
        showCaptchaMessage={showCaptchaMessage}
        formTouched={formTouched}
        formData={formData}
        errorMessage={errorMessage}
        validateForm={validateForm}
        updateValue={updateValue}
        captchaRef={captchaRef}
        setSelectedPlan={setSelectedPlan}
        onRegisterSubmit={onRegisterSubmit}
        registerStatus={registerStatus}
      />
    </div>
    
    <CTA />
  </ContentTwoSided>
)

export default RegisterFormWrapper
